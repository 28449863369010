import React, { useCallback, useEffect, useState } from 'react';

import ContentGrid from "../../components/ContentGrid";
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';

import { Container, FooterPagination, GridContainer, MainContainer, PuBliCard, SectionMaterials, Showing, TableTitle } from './styles';

import AsideMenuItems from '../../components/AsideMenuItems';
import InputCheckbox from '../../components/InputCheckbox';
import SelectInput from '../../components/SelectInput';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import TooltipAdapter from '../../components/TooltipAdapter';
import { FlashCardButtonOpen, FormModalNotesContainer, HeaderTitle, MobileTable, NoneProgress } from '../CronogramaDisciplina/styles';
import Wysiwyg from '../CronogramaDisciplina/Wysiwyg';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { IAreasProps } from '../CronogramaDisciplina';
import { useAuth } from '../../hooks/auth';
import { useHistory } from 'react-router-dom';
import Loader from '../../components/Loader';

import CronogramaRedacaoComponent from '../CronogramaRedacao/CronogramaRedacaoComponent';
import Pagination from '../../components/Pagination';
import InputDebounceAdapter from '../../components/InputDebounceAdapter';

export interface IMaterialProps {
  page: number;
  maxPages: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  records?: {
    id: string;
    category_id: string;
    exam_name: string;
    name: string;
    description: string;
    thumbnail: string;
    created_at: string;
    updated_at: string;
    thumbnail_url: string;
    file: string;
    file_url: string;
    category: {
      id: string;
      name: string;
      created_at: string;
      updated_at: string;
    };
  }[];
}

export interface ICategoryProps {
  value: string | number;
  label: string | number;
}

const Redacione: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [mobileTable, setMobileTable] = useState(false);

  const [loading, setLoading] = useState(false);
  const [areas, setAreas] = useState<IAreasProps[]>([]);

  const [materials, setMaterials] = useState<IMaterialProps>({} as IMaterialProps);
  const [modelosProntos, setModelosProntos] = useState<IMaterialProps>({} as IMaterialProps);

  const [search, setSearch] = useState('');

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const slug = 'redacao';

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get(`/subjects/${slug}/areas/`);
        setAreas(response.data);
      } catch (error) {
        console.log(error);
        history.push("/cronograma");
        toast.error(
          "🤷‍♂️ Desculpe, a página que você tentou acessar não foi encontrada!",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [slug, history, user]);

  // List MATERIALS
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        //window.scrollTo(0, 0);

        const response = await api.get<IMaterialProps>('/materials',
          { params: { search: search, pageIndex: currentPage - 1, pageSize: 9, categories: ['Redação'] } }
        );

        console.log('LIST MATERIAIS >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setShowingTo(response.data.showingTo);
        setShowingFrom(response.data.showingFrom);
        setMaxPages(response.data.maxPages);
        setTotalRecords(response.data.totalRecords);

        setMaterials(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [search, currentPage]);

  // List MATERIALS
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        //window.scrollTo(0, 0);

        const response = await api.get<IMaterialProps>('/materials',
          { params: { search: search, pageIndex: 0, pageSize: 50, categories: ['Modelos de Redação'] } }
        );

        setModelosProntos(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [search, currentPage]);

  // Get window dimensions
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  }

  // verifica a dimensão do dispositivo
  useEffect(() => {
    if (windowDimensions.width <= 875) {
      setMobileTable(true);
    } else {
      setMobileTable(false);
    }
  }, [windowDimensions]);

  useEffect(() => {
    const handleResize = () => {
      const { width, height } = getWindowDimensions();
      setWindowDimensions({ width, height });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleUpdateTheory = useCallback(
    async (checked: boolean, subarea_id: string) => {
      try {
        await api.patch(`/schedules/theory`, {
          subarea_id: subarea_id,
          theory: checked,
        });
      } catch (error) {
        console.log(error);
      }
    }, []);

  const handleUpdateAbstract = useCallback(
    async (checked: boolean, subarea_id: string) => {
      try {
        await api.patch(`/schedules/abstract`, {
          subarea_id: subarea_id,
          abstract: checked,
        });
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  const handleRevisionValue = useCallback(
    async (value: string, subarea_id: string) => {
      try {
        const response = await api.patch(`/schedules/revision`, {
          revision: value,
          subarea_id: subarea_id,
        });
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  const handleExerciseValue = useCallback(
    async (value: string, subarea_id: string) => {
      console.log({ value, subarea_id });
      try {
        const response = await api.patch(`/schedules/exercise`, {
          exercise: value === '' ? null : value,
          subarea_id: subarea_id,
        });
        console.log(response.data);

        toast.success('Exercício atualizado com sucesso! ✅');
      } catch (error) {
        console.log(error);
      }
    }, []);


  const handleOpenModalNote = useCallback((subarea_id: string, note?: string) => {
    let modalNotes = withReactContent(Swal);

    modalNotes.fire({
      html: (
        <FormModalNotesContainer>
          <header>
            <h1>{note ? 'Eitar' : 'Adicionar'} comentário</h1>
          </header>
          <main>
            <input type="hidden" name="subarea_id" value={subarea_id} />
            <Wysiwyg
              defaultValue={note}
              subarea_id={subarea_id}
              onSubmit={(newValue) => {
                setAreas((oldState) => {
                  const newState = oldState.map((area) => {
                    const newSubareas = area.subareas.map((subarea) => {
                      if (subarea.id === subarea_id) {

                        return {
                          ...subarea,
                          schedules: [
                            {
                              ...subarea.schedules[0],
                              notes: newValue,
                            },
                          ],
                        };
                      }

                      return subarea;
                    });

                    return {
                      ...area,
                      subareas: newSubareas,
                    };
                  });

                  return newState;
                });
              }}
            />
          </main>
        </FormModalNotesContainer>
      ),
      background: "#fff",
      backdrop: "rgba(110, 97, 198, .1)",
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
      width: mobileTable ? "100%" : '700px',
    });
  }, [mobileTable]);


  const LINK_DISCOUT_PROMO = 'https://checkout.redacione.com.br/f6016371-4bb0-4413-a48e-2f55434c6b2d/?coupon=EDUCACIONE';

  return (
    <Container>
      <GridContainer>
        <aside>
          <AsideMenuItems />
        </aside>
        <main>
          <PuBliCard>
            <a href={LINK_DISCOUT_PROMO} target="_blank" rel="noreferrer">
              <img src={`https://redacione-api.s3.sa-east-1.amazonaws.com/discounts/plataforma-redacione.jpg`} alt="Plataforma educacione" />
            </a>
          </PuBliCard>
          <Tabs
            tabs={[
             /*  {
                title: (
                  <TableTitle>
                    AULAS
                  </TableTitle>
                ),
                content: (
                  <MainContainer>
                    <h1>Aulas</h1>
                  </MainContainer>
                ),
              }, */
              {
                title: (
                  <TableTitle>
                    PROPOSTAS DE TEMA
                  </TableTitle>
                ),
                content: (
                  <MainContainer>
                    <CronogramaRedacaoComponent />
                  </MainContainer>
                ),
              },
              {
                title: (
                  <TableTitle>
                    MODELOS DE REDAÇÃO
                  </TableTitle>
                ),
                content: (
                  <MainContainer>
                    <SectionMaterials>
                      {modelosProntos.records?.map((modeloRedacao) => (
                        <a href={modeloRedacao.file_url} key={modeloRedacao.id} target="_blank" rel="noreferrer">
                          <header>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none" /><polyline points="216 152 184 152 184 208" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><line x1="208" y1="184" x2="184" y2="184" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><path d="M48,192H64a20,20,0,0,0,0-40H48v56" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><path d="M112,152v56h16a28,28,0,0,0,0-56Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><path d="M48,112V40a8,8,0,0,1,8-8h96l56,56v24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><polyline points="152 32 152 88 208 88" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /></svg>
                          </header>
                          <main>
                            <h5>{modeloRedacao.name}</h5>
                          </main>
                        </a>
                      ))}
                    </SectionMaterials>
                  </MainContainer>
                ),
              },
              {
                title: (
                  <TableTitle>
                    MATERIAIS
                  </TableTitle>
                ),
                content: (
                  <MainContainer>
                    <SectionMaterials>
                      {loading && <Loader isFixed={false} borderRadius="0px" />}
                      {materials.records?.map((material) => (
                        <a href={material.file_url} key={material.id} target="_blank" rel="noreferrer">
                          <header>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none" /><polyline points="216 152 184 152 184 208" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><line x1="208" y1="184" x2="184" y2="184" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><path d="M48,192H64a20,20,0,0,0,0-40H48v56" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><path d="M112,152v56h16a28,28,0,0,0,0-56Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><path d="M48,112V40a8,8,0,0,1,8-8h96l56,56v24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><polyline points="152 32 152 88 208 88" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /></svg>
                          </header>
                          <main>
                            <h5>{material.name}</h5>
                          </main>
                        </a>
                      ))}
                    </SectionMaterials>
                    <FooterPagination>
                      <Showing>{`Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} materiais disponíveis`}</Showing>
                      {totalRecords !== 0 && <Pagination count={maxPages} currentPage={currentPage} setCurrentPage={setCurrentPage} scrollToTop={false} />}
                    </FooterPagination>
                  </MainContainer>
                ),
              },
              {
                title: (
                  <TableTitle>
                    CRONOGRAMA DE PRODUÇÃO TEXTUAL
                  </TableTitle>
                ),
                content: (
                  <MainContainer>
                    <main>
                      <section
                        style={
                          loading
                            ? { height: "300px", overflow: "hidden", position: "relative" }
                            : {}
                        }
                      >
                        {loading && <Loader isFixed={false} borderRadius="0px" />}
                        {areas.map((area, index) => (
                          <div key={index}>
                            <main id="main-scroll">
                              {!mobileTable && (
                                <section>
                                  <th className="text-center first-c">
                                    Taxa de Relevância
                                  </th>
                                  <th className="text-left">Assunto</th>
                                  <th className="rest">Teoria</th>
                                  <th className="rest">Resumo</th>
                                  <th className="rest">Exercício</th>
                                  <th className="rest">Anotações</th>
                                  <th className="rest last-c">Revisão</th>
                                </section>
                              )}
                              {area?.subareas?.map((subarea, index) => {

                                const defColor = {
                                  excellent: "#00cc91",
                                  good: "#2993e9",
                                  fair: "#f88e37",
                                  poor: "#ff0000",
                                };

                                const percentage = subarea.relevance;
                                const relevance = percentage >= 80 ? "excellent" : percentage >= 60 ? "good" : percentage >= 40 ? "fair" : "poor";
                                const relevanceColor = defColor[relevance];

                                return !mobileTable ? (
                                  <table
                                    key={index}
                                    style={
                                      index ===
                                        (Number(area?.subareas?.length) - 1 || 0)
                                        ? { border: "none" }
                                        : {}
                                    }
                                  >
                                    <tbody>
                                      <tr>
                                        <td className="text-center circle">
                                          {subarea.relevance !== 0 ? (
                                            <>
                                              <CircularProgressbar
                                                value={subarea.relevance}
                                                text={`${subarea.relevance}%`}
                                                strokeWidth={7}
                                                maxValue={100}
                                                minValue={0}
                                                styles={buildStyles({
                                                  // Rotation of path and trail, in number of turns (0-1)
                                                  rotation: 0,
                                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                  strokeLinecap: 'round',
                                                  // Text size
                                                  textSize: '24px',
                                                  // How long animation takes to go from one percentage to another, in seconds
                                                  pathTransitionDuration: 0.5,
                                                  // Can specify path transition in more detail, or remove it entirely
                                                  // pathTransition: 'none',
                                                  // Colors
                                                  pathColor: relevanceColor,
                                                  textColor: '#fff',
                                                  trailColor: '#adafca40',
                                                  backgroundColor: 'transparent',
                                                })}
                                              />
                                            </>
                                          ) : (
                                            <NoneProgress>-</NoneProgress>
                                          )}
                                        </td>
                                        <td className="isName">
                                          <h4>{subarea.name}</h4>
                                        </td>
                                        <td className="text-center">
                                          <InputCheckbox
                                            id={subarea.id + "_theory"}
                                            onChange={(event) =>
                                              handleUpdateTheory(
                                                event.target.checked,
                                                subarea.id
                                              )
                                            }
                                            checkedd={
                                              subarea?.schedules[0]?.theory || false
                                            }
                                          />
                                        </td>
                                        <td className="text-center">
                                          <InputCheckbox
                                            id={subarea.id + "_abstract"}
                                            onChange={(event: any) =>
                                              handleUpdateAbstract(
                                                event.target.checked,
                                                subarea.id
                                              )
                                            }
                                            checkedd={subarea?.schedules[0]?.abstract || false}
                                          />
                                        </td>
                                        <td className="text-center">
                                          <InputDebounceAdapter
                                            debounceTimeout={1000}
                                            minLength={1}
                                            type="number"
                                            placeholder="0-100"
                                            onChange={(e) => handleExerciseValue(e.target.value, subarea.id)}
                                            value={subarea?.schedules[0]?.exercise?.toString() || undefined}
                                            min={1}
                                            max={100}
                                          />
                                        </td>
                                        <td className="anottation">
                                          <TooltipAdapter
                                            cursor="pointer"
                                            onClick={() => handleOpenModalNote(subarea.id, (subarea?.schedules[0]?.notes || ''))}
                                            alt={subarea?.schedules[0]?.notes ? 'Ver anotação' : 'Adicionar anotação'}
                                          >
                                            <img className={subarea?.schedules[0]?.notes ? 'contain-note' : ''} src="https://educacione-api.s3.sa-east-1.amazonaws.com/logo/notes.png" alt="" />
                                          </TooltipAdapter>
                                        </td>
                                        <td className="text-center">
                                          <SelectInput
                                            options={[
                                              {
                                                value: "Selecione...",
                                                label: "Selecione...",
                                              },
                                              {
                                                value: "first-revision",
                                                label: "1ª revisão",
                                              },
                                              {
                                                value: "second-revision",
                                                label: "2ª revisão",
                                              },
                                              {
                                                value: "third-revision",
                                                label: "3ª revisão",
                                              },
                                              {
                                                value: "fourth-revision",
                                                label: "4ª revisão",
                                              },
                                              {
                                                value: "fifth-revision",
                                                label: "5ª revisão",
                                              },
                                            ]}
                                            defaultValue={
                                              subarea.schedules[0]?.revision ||
                                              "Selecione..."
                                            }
                                            onChange={(e) => {
                                              handleRevisionValue(
                                                e.target.value,
                                                subarea.id
                                              );
                                            }}
                                            placeHolder="Selectione..."
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                ) : (
                                  <MobileTable>
                                    <header
                                      key={index}
                                      style={
                                        index === 0 ? { borderRadius: "5px 5px 0 0" } : {}
                                      }
                                    >
                                      <aside>
                                        <h4>{subarea.name}</h4>
                                        <TooltipAdapter
                                          cursor="pointer"
                                          onClick={() => handleOpenModalNote(subarea.id, (subarea?.schedules[0]?.notes || ''))}
                                          alt={subarea?.schedules[0]?.notes ? 'Ver anotação' : 'Adicionar anotação'}
                                        >
                                          <img className={subarea?.schedules[0]?.notes ? 'contain-note' : ''} src="https://educacione-api.s3.sa-east-1.amazonaws.com/logo/notes.png" alt="" />
                                        </TooltipAdapter>
                                      </aside>
                                      {subarea.relevance > 0 && (
                                        <div>
                                          <CircularProgressbar
                                            value={subarea.relevance}
                                            text={`${subarea.relevance}%`}
                                            strokeWidth={6}
                                            maxValue={100}
                                            minValue={0}
                                            className="progress-bar-mobile"
                                            styles={buildStyles({
                                              // Rotation of path and trail, in number of turns (0-1)
                                              rotation: 0,
                                              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                              strokeLinecap: 'round',
                                              // Text size
                                              textSize: '24px',
                                              // How long animation takes to go from one percentage to another, in seconds
                                              pathTransitionDuration: 0.5,
                                              // Can specify path transition in more detail, or remove it entirely
                                              // pathTransition: 'none',
                                              // Colors
                                              pathColor: relevanceColor,
                                              textColor: '#fff',
                                              trailColor: '#adafca40',
                                              backgroundColor: 'transparent',
                                            })}
                                          />
                                        </div>
                                      )}
                                    </header>
                                    <main
                                      style={
                                        index ===
                                          (Number(area?.subareas?.length) - 1 || 0)
                                          ? { borderRadius: "0 0 5px 5px" }
                                          : {}
                                      }
                                    >
                                      <div>
                                        <h5>Teoria</h5>
                                        <InputCheckbox
                                          id={subarea.id + "_theory"}
                                          onChange={(event) =>
                                            handleUpdateTheory(
                                              event.target.checked,
                                              subarea.id
                                            )
                                          }
                                          checkedd={subarea?.schedules[0]?.theory || false}
                                        />
                                      </div>
                                      <div>
                                        <h5>Resumo</h5>
                                        <InputCheckbox
                                          id={subarea.id + "_abstract"}
                                          onChange={(event) =>
                                            handleUpdateAbstract(
                                              event.target.checked,
                                              subarea.id
                                            )
                                          }
                                          checkedd={
                                            subarea?.schedules[0]?.abstract || false
                                          }
                                        />
                                      </div>
                                      <div>
                                        <h5>Exercício</h5>
                                        <InputDebounceAdapter
                                          debounceTimeout={1000}
                                          minLength={1}
                                          type="number"
                                          placeholder="0-100"
                                          onChange={(e) => handleExerciseValue(e.target.value, subarea.id)}
                                          value={subarea?.schedules[0]?.exercise?.toString() || undefined}
                                          min={1}
                                          max={100}
                                        />
                                      </div>
                                      <div>
                                        <h5>Revisão</h5>
                                        <SelectInput
                                          options={[
                                            {
                                              value: "Selecione...",
                                              label: "Selecione...",
                                            },
                                            {
                                              value: "first-revision",
                                              label: "1ª revisão",
                                            },
                                            {
                                              value: "second-revision",
                                              label: "2ª revisão",
                                            },
                                            {
                                              value: "third-revision",
                                              label: "3ª revisão",
                                            },
                                            {
                                              value: "fourth-revision",
                                              label: "4ª revisão",
                                            },
                                            {
                                              value: "fifth-revision",
                                              label: "5ª revisão",
                                            },
                                          ]}
                                          defaultValue={
                                            subarea.schedules[0]?.revision || "Selecione..."
                                          }
                                          onChange={(e) => {
                                            handleRevisionValue(e.target.value, subarea.id);
                                          }}
                                          placeHolder="Selectione..."
                                        />
                                      </div>
                                    </main>
                                  </MobileTable>
                                )
                              }
                              )}
                            </main>
                          </div>
                        ))}
                      </section>
                    </main>
                  </MainContainer>
                ),
              },
            ]}
          />
        </main>
      </GridContainer>
      <ContentGrid>
        <Footer />
      </ContentGrid>
    </Container>
  );
}

export default Redacione;
