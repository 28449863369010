import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;

  .active {
    color: #ffffff;
    background: ${(props) => props.theme.colors.primary};
  }
`;

export const GridContainer = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 0.8fr 4.2fr;

  /* TABLET */
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
  }

  > aside {
    position: relative;

    /* TABLET */
    @media (max-width: 1280px) {
      display: none;
    }

    > ul {
      position: sticky;
      top: 1rem;
      border-radius: 10px;
      background: ${(props) => props.theme.colors.bgBoxMenu};
      box-shadow: ${(props) => props.theme.colors.shadowBox};
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 1rem;

      > li {
        margin: 0.25rem 0;
        display: flex;

        a {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          width: 100%;
          text-decoration: none;
          color: ${(props) => props.theme.colors.textColor};
          transition: all 0.25s ease-in-out;
          padding: 1rem;
          border-radius: 8px;
          font-weight: 600;
          font-size: 16px;

          &:hover {
            color: #ffffff;
            background: ${(props) => props.theme.colors.primary};
          }

          > svg {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  > main {
    display: flex;
    flex-direction: column;

    > header {
      margin-bottom: 0.5rem;
      padding: 1rem;
      border-radius: 10px;
      background: ${(props) => props.theme.colors.bgBoxMenu};
      box-shadow: ${(props) => props.theme.colors.shadowBox};
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-direction: column;

        > div {
          width: 100%;

          > div {
            width: 100%;

            > input, select {
              width: 100% !important;
            }
          }
        }
      }

      @media (max-width: 600px) {
        > div {
          display: flex;
          flex-direction: column !important;
        }
      }

      > aside {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;

        > p {
          font-size: 14px;
          color: #adafca;
          margin-top: 0.5rem;
        }
      }

      > div {
        margin-top: 1rem;
        display: flex;
        gap: 1rem;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        > div {
          position: relative;

          > select {
            position: relative;
            min-width: 11rem;
            padding: 7px 10px;
            border-radius: 5px;
            border: 1px solid ${(props) => props.theme.colors.gray}30;
            color: ${(props) => props.theme.colors.textColor};
            background: ${(props) => props.theme.colors.bgBox};
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: "";
            height: 42px;

            &::-ms-expand {
              display: none;
            }

            &:focus {
              border-color: #615dfa;
            }

            > option {
              color: ${(props) => props.theme.colors.textColor};
              font-weight: 600;
            }
          }

          > span {
            margin-left: -1rem;
            position: relative;

            position: absolute;
            right: 12px;
            top: calc(50% - 3px);
            font-size: 10px;
            stroke-width: 2px;
            stroke: #9098a9;
            fill: none;
            stroke-linecap: round;
            stroke-linejoin: round;
            pointer-events: none;
            user-select: none;
            transform: rotate(90deg);
            z-index: 9;
          }
        }

        > div {
          position: relative;

          > input {
            padding: 7px 10px;
            border-radius: 5px;
            padding-left: 2rem;
            border: 1px solid ${(props) => props.theme.colors.gray}30;
            color: ${(props) => props.theme.colors.textColor};
            background: transparent;
            font-size: 13px;
            width: 180px;
            height: 42px;
            transition: all 0.25s ease-in-out;

            &:focus {
              width: 220px;
            }
          }

          > svg {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            color: ${(props) => props.theme.colors.gray}80;
        }
      }
    }

    > section {
      border-radius: 10px;
      padding: 1rem;
      background: ${(props) => props.theme.colors.bgBoxMenu};
      box-shadow: ${(props) => props.theme.colors.shadowBox};
    }
  }
`;

export const NotFoundMaterials = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;

  > p {
    font-size: 18px;
    color: ${(props) => props.theme.colors.textColor};
  }
`;
