import React, { useEffect, useState } from 'react';

import ContentGrid from "../../components/ContentGrid";

import Footer from '../../components/Footer';

import { Container, GridContainer, } from './styles';


import AsideMenuItems from '../../components/AsideMenuItems';
import HeaderInfo from '../../components/HeaderInfo';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';

export interface IMonstersProps {
  id: string;
  monster_name: string;
  monster_title: string;
  monster_image: string;
  order: number;
  day_1_questions: string;
  day_1_answers: string;
  day_2_questions: string;
  day_2_answers: string;
  day_1_questions_url: string;
  day_1_answers_url: string;
  day_2_questions_url: string;
  day_2_answers_url: string;
  created_at: string;
  updated_at: string;
  user_practices?: IUserPractices[];
  monster_image_url: string;
}

export interface IUserPractices {
  id: string;
  user_id: string;
  practice_id: string;
  estudei_acertei: string;
  estudei_errei: string;
  nao_estudei_acertei: string;
  nao_estudei_errei: string;
  created_at: string;
  updated_at: string;
}


const SimuEdu: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const [monsters, setMonsters] = useState<IMonstersProps[]>([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get('/monsters', {
          params: { pageIndex: 0, pageSize: 100, },
        });

        setMonsters(response.data.records);
      } catch (error) {
        setLoading(false);
        console.error('Erro ao carregar simulados:', error);
        toast.error('Erro ao carregar simulados');
      }
      finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <Container>
        <GridContainer>
          <aside>
            <AsideMenuItems />
          </aside>
          <main>
            <section>
              <header>
                <HeaderInfo supTitle="Simulados" title={`SimuEDU`} margin="0" showSendButton={false} />
                <p>Destrave seu potencial e alcance a aprovação!</p>
              </header>
              <main style={loading ? { paddingBottom: '10rem' } : {}}>
                {loading && <Loader isFixed={false}  />}
                {!loading && monsters.map((monster, index) => (
                  <Link to={`/simuedu/${monster.id}`} key={monster.id}>
                    <header className={monster.user_practices?.length ? 'empty' : ''}>
                      <img
                        src={`https://educacione.s3.sa-east-1.amazonaws.com${monster.monster_image}`}
                        alt={monster.monster_name}
                        loading='lazy'
                      />
                    </header>
                    <main>
                      <h5>{monster.monster_name}</h5>
                      <p>{monster.monster_title}</p>
                    </main>
                    <footer>
                      <p>
                        Simulado {index + 1}
                      </p>
                    </footer>
                  </Link>
                ))}
              </main>
            </section>
          </main>
        </GridContainer>

        <ContentGrid>
          <Footer />
        </ContentGrid>
      </Container>
    </>
  );
}

export default SimuEdu;
