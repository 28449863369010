import React, { useEffect, useState } from 'react';

import ContentGrid from "../../components/ContentGrid";

import Footer from '../../components/Footer';

import { Container, GridContainer, MainInner, Section2, } from './styles';

import AsideMenuItems from '../../components/AsideMenuItems';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { IMonstersProps } from '../SimuEdu';
import Button from '../../components/Button';


const SimuEduInner: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [monster, setMonster] = useState<IMonstersProps>({} as IMonstersProps);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/monsters/${id}`);
        setMonster(response.data);
      } catch (error) {
        console.error('Erro ao carregar simulado:', error);
        toast.error('Erro ao carregar simulado');
      }
      finally { }
    })();
  }, [id]);

  return (
    <>
      <Container>
        <GridContainer>
          <aside>
            <AsideMenuItems />
          </aside>
          <main>
            <section>
              <header>
                <div>
                  <h1>{monster.monster_name}</h1>
                  <p>{monster.monster_title}</p>
                </div>
                <img
                  src={`https://educacione.s3.sa-east-1.amazonaws.com${monster.monster_image}`}
                  alt={id}
                />
              </header>
              <main>
                <MainInner>
                  <aside>
                    <header>
                      <h1>Primeiro dia</h1>
                    </header>
                    <main>
                      <a href={`${monster.day_1_questions_url}`} target="_blank" rel="noreferrer">
                        <header>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none" /><circle cx="128" cy="128" r="96" opacity="0.2" /><circle cx="128" cy="128" r="96" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><polyline points="132 176 132 80 108 96" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /></svg>
                        </header>
                        <main>
                          <h5>Prova.pdf</h5>
                        </main>
                      </a>

                      <a href={`${monster.day_1_answers_url}`} target="_blank" rel="noreferrer">
                        <header>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none" /><path d="M32,216V56a8,8,0,0,1,8-8H216a8,8,0,0,1,8,8V216l-32-16-32,16-32-16L96,216,64,200Z" opacity="0.2" /><path d="M32,216V56a8,8,0,0,1,8-8H216a8,8,0,0,1,8,8V216l-32-16-32,16-32-16L96,216,64,200Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><polyline points="64 160 96 96 128 160" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><line x1="72" y1="144" x2="120" y2="144" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><line x1="144" y1="128" x2="192" y2="128" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><line x1="168" y1="104" x2="168" y2="152" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /></svg>
                        </header>
                        <main>
                          <h5>Gabarito.pdf</h5>
                        </main>
                      </a>
                    </main>
                  </aside>
                  <aside>
                    <header>
                      <h1>Segundo dia</h1>
                    </header>
                    <main>
                      <a href={`${monster.day_2_questions_url}`} target="_blank" rel="noreferrer">
                        <header>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none" /><circle cx="128" cy="128" r="96" opacity="0.2" /><path d="M152,176H104l43.17-57.56A24,24,0,1,0,105.37,96" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><circle cx="128" cy="128" r="96" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /></svg>
                        </header>
                        <main>
                          <h5>Prova.pdf</h5>
                        </main>
                      </a>

                      <a href={`${monster.day_2_answers_url}`} target="_blank" rel="noreferrer">
                        <header>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none" /><path d="M32,216V56a8,8,0,0,1,8-8H216a8,8,0,0,1,8,8V216l-32-16-32,16-32-16L96,216,64,200Z" opacity="0.2" /><path d="M32,216V56a8,8,0,0,1,8-8H216a8,8,0,0,1,8,8V216l-32-16-32,16-32-16L96,216,64,200Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><polyline points="64 160 96 96 128 160" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><line x1="72" y1="144" x2="120" y2="144" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><line x1="144" y1="128" x2="192" y2="128" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><line x1="168" y1="104" x2="168" y2="152" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /></svg>
                        </header>
                        <main>
                          <h5>Gabarito.pdf</h5>
                        </main>
                      </a>
                    </main>
                  </aside>
                </MainInner>
              </main>
            </section>

            <Section2>
              <header>
                <h1>Controle de acertos</h1>
                <p>Preencha abaixo as questões que você acertou em cada situação de acordo com o seu estudo.</p>
              </header>

              <form
                noValidate={false}
                onSubmit={async (e) => {
                  e.preventDefault();

                  const form = e.target as HTMLFormElement;

                  const data = new FormData(form);

                  const values = {
                    estudei_acertei: data.get('estudei_acertei') as string,
                    estudei_errei: data.get('estudei_errei') as string,
                    nao_estudei_acertei: data.get('nao_estudei_acertei') as string,
                    nao_estudei_errei: data.get('nao_estudei_errei') as string,
                  };

                  console.log(values);

                  try {
                    setLoading(true);
                    const response = await api.post(`/monsters/${id}/kill`, values);
                    if (response.status === 200) {
                      toast.success('Planilha salva com sucesso! ✅');
                    }
                  } catch (error) {
                    console.error('Erro ao salvar dados:', error);
                    toast.error('Erro ao salvar dados');
                  }
                  finally {
                    setLoading(false);
                  }
                }}
              >
                <main>
                  <div>
                    <label>Estudei e acertei</label>
                    <textarea
                      name="estudei_acertei"
                      defaultValue={monster?.user_practices && monster?.user_practices[0]?.estudei_acertei}
                    />
                  </div>
                  <div>
                    <label>Estudei e não acertei</label>
                    <textarea name="estudei_errei"
                      defaultValue={monster?.user_practices && monster?.user_practices[0]?.estudei_errei}
                    />
                  </div>
                  <div>
                    <label>Não estudei e acertei</label>
                    <textarea name="nao_estudei_acertei"
                      defaultValue={monster?.user_practices && monster?.user_practices[0]?.nao_estudei_acertei}
                    />
                  </div>
                  <div>
                    <label>Não estudei e não acertei</label>
                    <textarea name="nao_estudei_errei"
                      defaultValue={monster?.user_practices && monster?.user_practices[0]?.nao_estudei_errei}
                    />
                  </div>
                </main>
                <Button color='primary' type='submit' loading={loading}>Salvar</Button>
                <p>Os simulados são considerados como "realizados" quando você salva o seu controle de acertos.</p>
              </form>
            </Section2>
          </main>
        </GridContainer>

        <ContentGrid>
          <Footer />
        </ContentGrid>
      </Container>
    </>
  );
}

export default SimuEduInner;
