import React from 'react';


import ContentGrid from "../../components/ContentGrid";
import Footer from '../../components/Footer';

import { Container, GridContainer } from './styles';


import AsideMenuItems from '../../components/AsideMenuItems';
import CronogramaRedacaoComponent from './CronogramaRedacaoComponent';


const CronogramaRedacao: React.FC = () => {

  return (
    <>
      <Container>
        <GridContainer>
          <aside>
            <AsideMenuItems />
          </aside>
          <CronogramaRedacaoComponent />
        </GridContainer>

        <ContentGrid>
          <Footer />
        </ContentGrid>
      </Container>
    </>
  );
}

export default CronogramaRedacao;
