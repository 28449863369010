import React, { useState } from "react";

import { TabButton, TabContent, TabList, TabsContainer } from "./styles";

interface Tab {
  title: React.ReactNode;
  content?: React.ReactNode;
}

interface TabsProps {
  tabs: Tab[];
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <TabsContainer>
      <TabList>
        {tabs.map(
          (tab, index) =>
            tab.content && (
              <TabButton
                key={index}
                isActive={index === activeIndex}
                onClick={() => handleClick(index)}
                className={index === activeIndex ? "active-tab" : "inactive-tab"}
              >
                {tab.title}
              </TabButton>
            )
        )}
      </TabList>
      {tabs.map((tab, index) => (
        <TabContent
          key={index}
          className={index === activeIndex ? "active-tab" : "inactive-tab"}
        >
          {tab.content}
        </TabContent>
      ))}
    </TabsContainer>
  );
};

export default Tabs;
