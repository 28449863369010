import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;

  .progress-bar-circ-container {
    width: auto !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  .progress-bar-percent {
    color: ${props => props.theme.colors.textColor};
  }

  .active {
    color: #ffffff;
    background: ${props => props.theme.colors.primary};
  }
`;

export const GridContainer = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 0.8fr 4.2fr;

  /* TABLET */
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
  }

  > aside {
    position: relative;

    /* TABLET */
    @media (max-width: 1280px) {
      display: none;
    }

    > ul {
      position: sticky;
      top: 1rem;
      border-radius: 10px;
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      background: ${props => props.theme.colors.bgBoxMenu};
      box-shadow: ${props => props.theme.colors.shadowBox};

      > li {
        margin: .25rem 0;
        display: flex;

        a {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          width: 100%;
          text-decoration: none;
          color: ${props => props.theme.colors.textColor};
          transition: all .25s ease-in-out;
          padding: 1rem;
          border-radius: 8px;
          font-weight: 600;
          font-size: 16px;

          &:hover {
            color: #ffffff;
            background: ${props => props.theme.colors.primary};
          }

          > svg {
            margin-right: .5rem;
          }
        }
      }
    }
  }

  > section {
    position: relative;
    padding: 1rem;
    background: ${props => props.theme.colors.bgBoxMenu};
    border-radius: 10px;
    box-shadow: ${props => props.theme.colors.shadowBox};
  }

`;
