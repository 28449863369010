import React, { useEffect, useState } from 'react';

import ContentGrid from "../../components/ContentGrid";
import Footer from '../../components/Footer';

import { Container, GridContainer, NotFoundMaterials } from './styles';

import AsideMenuItems from '../../components/AsideMenuItems';
import { FooterPagination, SectionMaterials, Showing } from '../Redacione/styles';
import { IMaterialProps } from '../Redacione';
import Loader from '../../components/Loader';
import api from '../../services/api';
import Pagination from '../../components/Pagination';
import Icon from '../../components/Icon';
import { FaSearch } from 'react-icons/fa';
import InputDebounceAdapter from '../../components/InputDebounceAdapter';
import HeaderInfo from '../../components/HeaderInfo';

const Materiais: React.FC = () => {
  const [materials, setMaterials] = useState<IMaterialProps>({} as IMaterialProps);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  // Paginação
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  // Estados separados para categorias
  const [categories, setCategories] = useState<string[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<{ value: string; label: string }[]>([]);

  // Buscar categorias apenas uma vez ao montar o componente
  useEffect(() => {
    (async () => {
      try {
        const excludeCategories = ['Modelos de Redação', 'Redação'];

        const responseCategories = await api.get('/materials/categories/options');
        const categories = responseCategories.data.filter((category: { label: string }) => !excludeCategories.includes(category.label));
        setCategoryOptions(categories);
      } catch (error) {
        console.error('Erro ao carregar categorias:', error);
      }
    })();
  }, []);

  // Buscar materiais sempre que `search`, `currentPage` ou `categories` mudarem
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get<IMaterialProps>('/materials', {
          params: { search, pageIndex: currentPage - 1, pageSize: 20, categories: !categories.length ? categoryOptions.map(category => category.value) : categories },
        });

        setShowingTo(response.data.showingTo);
        setShowingFrom(response.data.showingFrom);
        setMaxPages(response.data.maxPages);
        setTotalRecords(response.data.totalRecords);

        setMaterials(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [search, currentPage, categories]);

  return (
    <Container>
      <GridContainer>
        <aside>
          <AsideMenuItems />
        </aside>

        <main>
          <header>
            <aside>
              <HeaderInfo supTitle="Download" title={`Materiais complementares`} margin="0" showSendButton={false} />
              <p>Encontre aqui materiais de apoio para a sua preparação.</p>
            </aside>
            <div>
              <div>
                <select
                  name="categories"
                  id="categories"
                  onChange={(e) => {
                    setCategories(e.target.value ? [e.target.value] : []);
                  }}
                >
                  <option value="">Selecione uma categoria</option>
                  {categoryOptions.map((category, index) => (
                    <option key={index} value={category.label}>
                      {category.label}
                    </option>
                  ))}
                </select>
                <Icon icon="icon-icon-big-arrow" color="#9098a9" size="12px" />
              </div>
              <div>
                <FaSearch color='#9098a960' size={12} />
                <InputDebounceAdapter
                  placeholder="Pesquisar..."
                  debounceTimeout={500}
                  minLength={3}
                  type='text'
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </header>

          <SectionMaterials style={materials?.records && materials?.records?.length === 0 ? { display: 'flex' } : {}}>
            {loading ? <Loader isFixed={false} borderRadius="0px" /> : (
              materials?.records && materials?.records?.length > 0 ? materials.records?.map((material, index) => (
                <a href={material.file_url} key={material.id} target="_blank" rel="noreferrer">
                  <header>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none" /><polyline points="216 152 184 152 184 208" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><line x1="208" y1="184" x2="184" y2="184" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><path d="M48,192H64a20,20,0,0,0,0-40H48v56" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><path d="M112,152v56h16a28,28,0,0,0,0-56Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><path d="M48,112V40a8,8,0,0,1,8-8h96l56,56v24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /><polyline points="152 32 152 88 208 88" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" /></svg>
                  </header>
                  <main>
                    <h5>{material.name}</h5>
                  </main>
                </a>
              )) : (
                <NotFoundMaterials>
                  <p>Nenhum material encontrado. 😢</p>
                </NotFoundMaterials>
              )
            )}
          </SectionMaterials>
          <FooterPagination>
            <Showing>{`Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} materiais disponíveis`}</Showing>
            {totalRecords !== 0 && <Pagination count={maxPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
          </FooterPagination>
        </main>
      </GridContainer>
      <ContentGrid>
        <Footer />
      </ContentGrid>
    </Container>
  );
}

export default Materiais;
