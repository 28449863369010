import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;

  .active {
    color: #ffffff;
    background: ${(props) => props.theme.colors.primary};
  }
`;

export const MainContainer = styled.section`
  position: relative;
  width: 100%;
  padding: 0 1rem 2rem 1rem !important;
  color: ${(props) => props.theme.colors.textColor};
  background: ${(props) => props.theme.colors.bgBoxMenu};
  border-radius: 0 0 10px 10px;
  box-shadow: ${(props) => props.theme.colors.shadowBox};

  > main {
    position: relative;
    display: flex;
    flex-direction: column;

    > section:nth-child(1) {
      border-radius: 10px;
      background: ${(props) => props.theme.colors.bgBoxMenu};
      box-shadow: ${(props) => props.theme.colors.shadowBox};

      .text-left {
        text-align: left !important;
        justify-content: start !important;
      }
      .text-center {
        text-align: center !important;
      }
      .isName {
        width: 27%;
      }

      .thead-extra {
        border-radius: 5px 5px 0 0;
        width: 100%;
      }

      .first-c {
        border-radius: 5px 0 0 0;
      }

      .last-c {
        border-radius: 0 5px 0 0;
      }

      > div {
        > main {
          position: relative;
          border-radius: 5px;
          box-shadow: ${(props) => props.theme.colors.shadowMenu};
          border: 1px solid ${(props) => props.theme.colors.separator};

          > section {
            position: sticky;
            top: 1rem;
            border-radius: 5px 5px 0 0;
            width: 100%;
            display: grid;
            grid-template-columns: 1.5fr 2.3fr 0.8fr 0.8fr 0.8fr 0.7fr 2fr;
            z-index: 9;
            opacity: 1;
            box-shadow: 0px 12px 35px -13px ${(props) => props.theme.colors.primary},
              0px 25px 47px 2px rgba(0, 0, 0, 0);

            &:before {
              content: "";
              position: absolute;
              width: 101%;
              height: 20px;
              top: -18px;
              background: ${(props) => props.theme.colors.bgBoxMenu};
              border: 2px solid ${(props) => props.theme.colors.bgBoxMenu};
              left: -6px;
              //background: red;
            }

            > tr,
            th,
            td {
              background: #6c23c0;
              color: #ffffff;
              font-weight: bold;
              padding: 12px 15px;
              border-collapse: collapse;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 999999;
            }
          }

          > table {
            position: relative;
            width: 100%;
            border-collapse: collapse;
            font-size: 0.9em;
            overflow: hidden;
            border-bottom: 1px solid ${(props) => props.theme.colors.separator};

            td {
              padding: 12px 15px;
              color: ${(props) => props.theme.colors.textColor};

              &.anottation {
                > div {
                  text-align: center !important;

                  > img {
                    width: 26px;
                    filter: grayscale(1);

                    &.contain-note {
                      filter: unset !important;
                    }
                  }
                }
              }

              > input {
                background: ${(props) => props.theme.colors.bgBoxMenu};
                border: 1px solid ${(props) => props.theme.colors.separator};
                box-shadow: ${(props) => props.theme.colors.shadowMenu};
                color: ${(props) => props.theme.colors.textColor};
                padding: 5px;
                border-radius: 4px;
                width: 80px;
                text-align: center;
              }
            }

            > thead {
              border-collapse: collapse;
              border-radius: 5px 5px 0 0;

              > tr,
              th,
              td {
                background: #6c23c0;
                color: #ffffff;
                font-weight: bold;
                padding: 12px 15px;
                border-collapse: collapse;
              }
            }

            > tbody {
              border-collapse: collapse;

              > tr {
                border-collapse: collapse;
                margin: 5px;

                &:last-child {
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GridContainer = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 0.8fr 4.2fr;

  /* TABLET */
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
  }

  > aside {
    position: relative;

    /* TABLET */
    @media (max-width: 1280px) {
      display: none;
    }

    > ul {
      position: sticky;
      top: 1rem;
      border-radius: 10px;
      background: ${(props) => props.theme.colors.bgBoxMenu};
      box-shadow: ${(props) => props.theme.colors.shadowBox};
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 1rem;

      > li {
        margin: 0.25rem 0;
        display: flex;

        a {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          width: 100%;
          text-decoration: none;
          color: ${(props) => props.theme.colors.textColor};
          transition: all 0.25s ease-in-out;
          padding: 1rem;
          border-radius: 8px;
          font-weight: 600;
          font-size: 16px;

          &:hover {
            color: #ffffff;
            background: ${(props) => props.theme.colors.primary};
          }

          > svg {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  > main {
    display: flex;
    flex-direction: column;

    > section:nth-child(2) {
      border-radius: 10px;
      padding: 1rem;
      background: ${(props) => props.theme.colors.bgBoxMenu};
      box-shadow: ${(props) => props.theme.colors.shadowBox};
    }

    > div {
      > div:nth-child(1) {
        // Tabs component
        background: ${(props) => props.theme.colors.bgBoxMenu};
        border-radius: 10px 10px 0 0;
        padding: 1rem;
        z-index: 99;

        @media (max-width: 1280px) {
          display: grid;
          grid-template-columns: 1fr;
          justify-content: flex-start;

          > button {
            margin-bottom: 0.5rem;
            padding: 1rem;
            border-radius: 8px;
            border: none;
            background: #adafca10;

            &.active-tab {
              background: ${(props) => props.theme.colors.primary};
              color: #ffffff;
            }

            > section {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
`;

/*  */
export const PuBliCard = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 6px;
  transition: all 0.45s ease-in-out;

  .blink-animation {
    animation: blink-animation 0.45s infinite alternate;
  }

  @keyframes blink-animation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  > p {
    margin: 0.25rem 0 0 0.5rem !important;
  }

  > a {
    text-decoration: none;
    width: 100%;

    > img {
      width: 100%;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  > p {
    margin-top: 1rem;
    font-size: 12px;
    margin-left: 0.5rem;
  }
`;

export const TableTitle = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  font-weight: 600;
`;

export const SectionMaterials = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  display: grid;
  min-height: 200px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  @media (max-width: 1280px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  > div, a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 100%;
    min-height: 180px;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    z-index: 9;
    box-shadow: ${(props) => props.theme.colors.shadowBox};
    background: ${(props) => props.theme.colors.bgBoxMenu};
    padding: 1rem;
    border: 1px solid ${(props) => props.theme.colors.separator};
    z-index: 999;
    text-decoration: none;

    &:hover {
      transform: translateY(-5px);
    }

    > header {
      position: relative;
      background: ${(props) => props.theme.colors.secondary}20;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.75rem;
      margin-bottom: 1.5rem;
      width: fit-content;
      border-radius: 6px;

      > svg {
        color: ${(props) => props.theme.colors.info};
        width: 38px;
        height: 38px;
      }
    }

    > main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > h5 {
        margin-top: 0.5rem;
        font-size: 14px;
        font-weight: 600;
        font-size: 14px;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${(props) => props.theme.colors.textColor};
        text-align: center;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        -webkit-box-orient: vertical;
      }
    }
  }
`;

export const FooterPagination = styled.footer`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Showing = styled.div`
  color: #9aa4bf95;
  margin-top: 2rem;
  text-align: center;
`;
