import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const GridContainer = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 0.8fr 4.2fr;

  /* LAPTOP */
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
  }

  > aside {
    position: relative;

    /* LAPTOP */
    @media (max-width: 1280px) {
      display: none;
    }

    > ul {
      position: sticky;
      top: 1rem;
      border-radius: 10px;
      background: ${(props) => props.theme.colors.bgBoxMenu};
      box-shadow: ${(props) => props.theme.colors.shadowBox};
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 1rem;

      > li {
        margin: 0.25rem 0;
        display: flex;

        a {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          width: 100%;
          text-decoration: none;
          color: ${(props) => props.theme.colors.textColor};
          transition: all 0.25s ease-in-out;
          padding: 1rem;
          border-radius: 8px;
          font-weight: 600;
          font-size: 16px;

          &:hover {
            color: #ffffff;
            background: ${(props) => props.theme.colors.primary};
          }

          > svg {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  > main {
    display: flex;
    flex-direction: column;

    > section {
      border-radius: 10px;
      padding: 2rem;
      background: ${(props) => props.theme.colors.bgBoxMenu};
      box-shadow: ${(props) => props.theme.colors.shadowBox};

      > header {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 2rem;

        > p {
          font-size: 14px;
          color: #adafca;
        }
      }

      > main {
        position: relative;
        display: grid;
        gap: 1rem;
        margin-top: 1rem;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        justify-content: space-between;
        box-sizing: border-box;

        @media (max-width: 1280px) {
          grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        }

        @media (max-width: 768px) {
          grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        }

        @media (max-width: 480px) {
          grid-template-columns: minmax(0, 1fr);
        }

        > a {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          border-radius: 10px;
          padding: 1rem;
          background: ${(props) => props.theme.colors.bgBoxMenu};
          border: 1px solid ${(props) => props.theme.colors.separator};
          box-shadow: ${(props) => props.theme.colors.shadowBox};
          outline: none;
          transition: 0.2s ease-in-out;
          cursor: pointer;
          text-decoration: none;
          text-align: center;
          justify-content: space-around;
          min-height: 210px;

          &:hover {
            background: ${(props) => props.theme.colors.bgContent};

            > header {
              transform: translateY(-0.25rem);
              filter: none !important;
            }
          }

          > header {
            display: flex;
            justify-content: center;
            position: relative;
            align-items: center;
            justify-content: center;
            transition: 0.2s ease-in-out;
            margin-top: -2rem;

            &.empty {
              filter: grayscale(100%) brightness(0.85);
            }

            &::before {
              content: "";
              border-radius: 50%;
              width: 6rem;
              height: 6rem;
              background: ${(props) => props.theme.colors.secondary};
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.4s ease-in-out;
              z-index: 1;
            }

            > img {
              //width: 100%;
              max-height: 128px;
              border-radius: 10px;
              z-index: 99999999 !important;
            }
          }

          > main {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            > h5 {
              font-size: 16px;
              color: ${(props) => props.theme.colors.textColor};
            }

            > p {
              font-size: 11px;
              color: #adafca;
              min-height: 32px;
              display: flex;
              text-align: center;
              justify-content: center;
              align-items: center;
            }
          }

          > footer {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            > p {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              font-size: 9px;
              font-weight: 800;
              background: ${(props) => props.theme.colors.separator};
              padding: 0.25rem 0.5rem;
              border-radius: 24px;
              text-transform: uppercase;
              font-weight: 600;
              color: ${(props) => props.theme.colors.secundaryTextColor};
            }
          }
        }
      }
    }
  }
`;
