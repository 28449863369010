import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TabList = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

interface TabButtonProps {
  isActive: boolean;
}

export const TabButton = styled.button<TabButtonProps>`
  font-size: 1rem;
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
  border: none;
  padding: 0 1rem 0.75rem 1rem;
  border-bottom: ${(props) => props.isActive ? `2px solid ${props.theme.colors.primary}` : "2px solid transparent"};
  background-color: transparent;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: ${props => props.theme.colors.textColor};
  user-select: none;

  &:hover {
    font-weight: bold;
    border-bottom: 2px solid ${props => props.theme.colors.primary};
  }
`;

export const TabContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  &.inactive-tab {
    opacity: 0;
    visibility: hidden !important;
    height: 0 !important;
    display: none;
  }

  &.active-tab {
    opacity: 1;
    visibility: visible;
  }

  p {
    text-align: justify;
  }
`;
