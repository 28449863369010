import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;

  .active {
    color: #ffffff;
    background: ${props => props.theme.colors.primary};
  }
`;

export const GridContainer = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 0.8fr 4.2fr;

  /* TABLET */
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
  }

  > aside {
    position: relative;

    /* TABLET */
    @media (max-width: 1280px) {
      display: none;
    }

    > ul {
      position: sticky;
      top: 1rem;
      border-radius: 10px;
      background: ${props => props.theme.colors.bgBoxMenu};
      box-shadow: ${props => props.theme.colors.shadowBox};
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 1rem;

      > li {
        margin: .25rem 0;
        display: flex;

        a {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          width: 100%;
          text-decoration: none;
          color: ${props => props.theme.colors.textColor};
          transition: all .25s ease-in-out;
          padding: 1rem;
          border-radius: 8px;
          font-weight: 600;
          font-size: 16px;

          &:hover {
            color: #ffffff;
            background: ${props => props.theme.colors.primary};
          }

          > svg {
            margin-right: .5rem;
          }
        }
      }
    }
  }

  > main {
    position: relative;
    border-radius: 10px;
    background: ${props => props.theme.colors.bgBoxMenu};
    box-shadow: ${props => props.theme.colors.shadowBox};
    padding: 2rem;

    /* TABLET */
    @media (max-width: 1280px) {

      > section {

        > div {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 1rem;
        }
      }
    }

    /* TABLET */
    @media (max-width: 850px) {

      > section {

        > div {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 1rem;
        }
      }
    }

    /* TABLET */
    @media (max-width: 700px) {

      > section {

        > div {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 1rem;
        }
      }
    }

    /* MOBILE */
    @media (max-width: 450px) {

      > section {

        > div {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 1rem;

          > div {
            //min-width: 110px;
          }
        }
      }
    }
  }
`;

export const MovieSuggestionGrid = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
  border-radius: 12px;

  /* gg */
  @media (max-width: 1200px) {
    grid-template-columns: repeat(5, 1fr);
  }
  /* TABLET */
  @media (max-width: 1000px) {
    grid-template-columns: repeat(4, 1fr);
  }
  /* TABLET */
  @media (max-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
  }
  /* MOBILE */
  @media (max-width: 520px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
