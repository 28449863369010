import React, { HTMLInputTypeAttribute } from 'react';

import { DebounceInput } from 'react-debounce-input';

interface IInputDebounceAdapterProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onChange(event: any): void;
  minLength: number;
  debounceTimeout: number;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  defaultValue?: string;
  value?: string;
}


const InputDebounceAdapter: React.FC<IInputDebounceAdapterProps> = ({ type, onChange, minLength, debounceTimeout, placeholder, defaultValue, value, ...rest }) => {
  return (
    <DebounceInput
      type={type}
      minLength={minLength}
      debounceTimeout={debounceTimeout}
      onChange={onChange}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={value}
      {...rest}
    />
  );
}

export default InputDebounceAdapter;
