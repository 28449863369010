import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const GridContainer = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 0.8fr 4.2fr;

  /* LAPTOP */
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
  }

  > aside {
    position: relative;

    /* LAPTOP */
    @media (max-width: 1280px) {
      display: none;
    }

    > ul {
      position: sticky;
      top: 1rem;
      border-radius: 10px;
      background: ${(props) => props.theme.colors.bgBoxMenu};
      box-shadow: ${(props) => props.theme.colors.shadowBox};
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 1rem;

      > li {
        margin: 0.25rem 0;
        display: flex;

        a {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          width: 100%;
          text-decoration: none;
          color: ${(props) => props.theme.colors.textColor};
          transition: all 0.25s ease-in-out;
          padding: 1rem;
          border-radius: 5px;
          font-weight: 600;
          font-size: 16px;

          &:hover {
            color: #ffffff;
            background: ${(props) => props.theme.colors.primary};
          }

          > svg {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  > main {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > section:nth-child(1) {
      border-radius: 10px;
      padding: 2rem;
      background: ${(props) => props.theme.colors.bgBoxMenu};
      box-shadow: ${(props) => props.theme.colors.shadowBox};

      > header {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        margin-bottom: 1rem;
        justify-content: space-between;
        text-align: center;

        > div {
          display: flex;
          flex-direction: column;
          text-align: left;

          > h1 {
            font-size: 3rem;
            font-weight: 700;
            color: ${(props) => props.theme.colors.textColor};
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-align: left;

            @media (max-width: 768px) {
              font-size: 2rem;
            }
          }

          > p {
            font-size: 14px;
            color: #adafca;
            font-weight: 600;
            text-transform: uppercase;

            @media (max-width: 768px) {
              font-size: 12px !important;
            }
          }
        }

        > img {
          height: 221px;
          object-fit: cover;
          border-radius: 10px;
          margin-top: -5rem;
          margin-bottom: -13rem;
          z-index: 99999;

          @media (max-width: 768px) {
            height: 120px;
            margin-top: -2rem;
          }
        }
      }

      > main {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: flex-start;

        > div > div {
          justify-content: flex-start;
        }
      }
    }
  }
`;

export const MainInner = styled.div`
  position: relative;
  margin-top: 1rem;
  position: relative;
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }

  > aside {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.separator};
    border-radius: 10px;
    padding: 0;
    overflow: hidden;

    > header {
      display: flex;
      display: flex;
      justify-content: center;
      text-align: center;
      padding: 0.75rem 0;
      background: ${(props) => props.theme.colors.primary};

      > h1 {
        font-size: 1.25rem;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
        text-transform: uppercase;
      }
    }

    > main {
      position: relative;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      padding: 1rem;

      > div,
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        width: 100%;
        height: 180px;
        transition: all 0.1s ease-in-out;
        cursor: pointer;
        z-index: 9;
        box-shadow: ${(props) => props.theme.colors.shadowBox};
        background: ${(props) => props.theme.colors.bgBoxMenu};
        padding: 1rem;
        border: 1px solid ${(props) => props.theme.colors.separator};
        z-index: 999;
        text-decoration: none;
        outline: none;

        &:hover {
          border: 1px solid ${(props) => props.theme.colors.info};
        }

        > header {
          position: relative;
          background: ${(props) => props.theme.colors.secondary}20;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.75rem;
          margin-bottom: 1rem;
          width: fit-content;
          border-radius: 6px;

          > svg {
            color: ${(props) => props.theme.colors.info};
            width: 38px;
            height: 38px;
          }
        }

        > main {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          > h5 {
            margin-top: 0.5rem;
            font-size: 14px;
            font-weight: 600;
            font-size: 14px;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            color: ${(props) => props.theme.colors.textColor};
            text-align: center;
          }
        }
      }
    }
  }
`;

export const Section2 = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 2rem;
  background: ${(props) => props.theme.colors.bgBoxMenu};
  box-shadow: ${(props) => props.theme.colors.shadowBox};

  > header {
    position: relative;
    display: flex !important;
    flex-direction: column !important;

    > h1 {
      font-size: 3rem;
      font-weight: 700;
      text-align: center !important;
      color: ${(props) => props.theme.colors.textColor};
    }

    > p {
      font-size: 16px;
      font-weight: 600;
      color: #adafca;
      margin-bottom: 2rem !important;
      text-align: center !important;
    }
  }

  > form {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > main {
      position: relative;
      display: grid !important;
      grid-template-columns: repeat(2, 1fr) !important;
      gap: 1rem;

      @media (max-width: 768px) {
        grid-template-columns: 1fr !important;
      }

      > div {
        position: relative;

        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }

        > label {
          font-size: 14px;
          font-weight: 600;
          color: ${(props) => props.theme.colors.textColor};
          margin-bottom: 0.5rem;
          position: absolute;
          top: 1rem;
          left: 1rem;
          color: #adafca80;
        }

        > textarea {
          width: fit-content;
          padding: 1rem;
          border-radius: 10px;
          background: ${(props) => props.theme.colors.bgBoxMenu};
          box-shadow: ${(props) => props.theme.colors.shadowBox};
          border: 1px solid ${(props) => props.theme.colors.separator};
          color: ${(props) => props.theme.colors.textColor};
          font-size: 16px;
          font-weight: 600;
          resize: true;
          max-width: fit-content;
          min-width: 100%;
          min-height: 200px;
          padding-top: 3rem;
          resize: none;
        }
      }
    }

    > Button {
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: center;
    }

    > p {
      font-size: 12px;
      font-weight: 600;
      color: #adafca;
      margin-top: 1rem;
      text-align: center;
    }
  }
`;
