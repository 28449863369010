import styled from "styled-components";

export const Container = styled.section`
  position: relative;
`;

export const Main = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colors.separator};
  border-top: none;
  border-radius: 10px;
`;

export const HeaderTables = styled.header`
  position: sticky;
  border-radius: 10px;
  top: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 25% 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  z-index: 9;
  opacity: 1;
  box-shadow: rgb(108, 35, 192) 0px 12px 35px -13px, rgba(0, 0, 0, 0) 0px 25px 47px 2px;

  &:before {
    content: "";
    position: absolute;
    width: 101%;
    height: 20px;
    top: -18px;
    background: ${(props) => props.theme.colors.bgBoxMenu};
    border: 2px solid ${(props) => props.theme.colors.bgBoxMenu};
    left: -6px;
  }

  > tr,
  th,
  td {
    background: #6c23c0;
    color: #ffffff;
    font-weight: bold;
    padding: 12px 15px;
    border-collapse: collapse;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    white-space: nowrap; /* AQUI */
    width: 100%;

    &.text-left {
      text-align: left !important;
      justify-content: start !important;
    }
    &.text-center {
      text-align: center !important;
    }
    &.isName {
      width: 25% !important;
    }

    &.thead-extra {
      border-radius: 5px 5px 0 0;
      width: 100%;
    }

    &.first-c {
      border-radius: 5px 0 0 0;
    }

    &.last-c {
      border-radius: 0 5px 0 0;
    }
  }
`;

export const Showing = styled.div`
  color: #9aa4bf95;
  margin-top: 2rem;
  text-align: center;
`;

export const MobileTable = styled.div`
  > header {
    padding: 12px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #6c23c0;
    border-collapse: collapse;

    > aside {
      display: flex;
      flex-direction: row;
      align-items: center;

      > button {
        margin-left: 10px;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        > img {
          width: 32px;
        }
      }


      > h4 {
        color: #fff;
      }
    }
  }

  > main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
    box-shadow: ${(props) => props.theme.colors.shadowMenu};
    display: grid !important;
    grid-template-columns: repeat(6, 1fr);
    padding-bottom: 1rem;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > input {
        width: 80%;
        border-radius: 4px;
        text-align: center;
        padding: 0.4rem 0;
        transition: all 0.2s ease-in-out;
        border: 1px solid ${(props) => props.theme.colors.separator};

        /* MOBILE */
        @media (max-width: 750px) {
          width: 90%;
        }

        &::-webkit-input-placeholder {
          text-align: center;
        }

        &:-moz-placeholder {
          text-align: center;
        }

        &:focus {
          border: 1px solid #615dfa;
        }

        &:read-only {
          background: ${(props) => props.theme.colors.separator};
          border: 1px solid ${(props) => props.theme.colors.separator};
          color: ${(props) => props.theme.colors.textColor};
          cursor: not-allowed;
          text-align: center;
          font-weight: 600;
        }
      }

      > h5 {
        margin: 0 0 8px 0;
        color: ${(props) => props.theme.colors.textColor};
      }

      > select {
        padding: 5px;
        border-radius: 4px;
        background: ${(props) => props.theme.colors.bgBoxMenu};
        border: 2px solid #b7b6c2;
        color: ${(props) => props.theme.colors.textColor};
      }
    }
  }

  select {
    background: ${(props) => props.theme.colors.bgBoxMenu} !important;
  }
`;

export const ContentGrid3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  /* TABLET */
  @media (max-width: 768px) {
  }
  /* MOBILE */
  @media (max-width: 480px) {
  }
`;

export const Table = styled.table`
  position: relative;
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9em;
  overflow: hidden;
  border-bottom: 1px solid ${(props) => props.theme.colors.separator};

  &:last-child {
    border: none;
  }

  .text-left {
    text-align: left !important;
    justify-content: start !important;
  }
  .text-center {
    text-align: center !important;
  }
  .isName {
    width: 25% !important;
  }

  .thead-extra {
    border-radius: 5px 5px 0 0;
    width: 100%;
  }

  .first-c {
    border-radius: 5px 0 0 0;
  }

  .last-c {
    border-radius: 0 5px 0 0;
  }

  td {
    padding: 12px 15px;
    color: ${(props) => props.theme.colors.textColor};

    > div {
      border-radius: 4px;
      text-align: center;
      width: 77px;

      > img {

        &.disabled {
          width: 32px;
          filter: grayscale(1);
          opacity: 0.5;
        }
      }


      > a {
        margin: 0;
        padding: 0;
        background: none;
        border: none;
        cursor: pointer;
        outline: none;

        > img {
          width: 32px;
        }
      }
    }

    > input {
      width: 70%;
      border-radius: 4px;
      text-align: center;
      padding: 0.4rem 0;
      transition: all 0.2s ease-in-out;
      border: 1px solid ${(props) => props.theme.colors.separator};

      &::-webkit-input-placeholder {
        text-align: center;
      }

      &:-moz-placeholder {
        text-align: center;
      }

      &:focus {
        border: 1px solid #615dfa;
      }

      &:read-only {
        background: ${(props) => props.theme.colors.separator};
        border: 1px solid ${(props) => props.theme.colors.separator};
        color: ${(props) => props.theme.colors.textColor};
        cursor: not-allowed;
        text-align: center;
        font-weight: 600;
      }
    }
  }

  > thead {
    border-collapse: collapse;
    border-radius: 5px 5px 0 0;

    > tr,
    th,
    td {
      background: #6c23c0;
      color: #ffffff;
      font-weight: bold;
      padding: 12px 15px;
      border-collapse: collapse;
    }
  }

  > tbody {
    border-collapse: collapse;

    > tr {
      border-collapse: collapse;
      margin: 5px;

      &:last-child {
        border: none;
      }
    }

    > td {

    }
  }
`;
