import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  grid-area: CT;
  color: ${props => props.theme.colors.white};
  display: flex;
  justify-content: center;
  margin: 0 16px; /* AQUI */
  max-width: 1280px;
  width: 100%;
  min-height: 100vh;

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.secondary};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track{
    background: ${props => props.theme.colors.tertiary};
  }
`;
