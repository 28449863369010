import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;

  .CircularProgressbar {
    position: relative;
    width: 55px;
    height: 55px;
  }

  .CircularProgressbar-text {
    color: ${(props) => props.theme.colors.textColor} !important;
    fill: ${(props) => props.theme.colors.textColor} !important;
    font-weight: 600;
  }

  .progress-bar-mobile .CircularProgressbar-text {
    fill: #fff !important;
  }

  .active {
    color: #ffffff;
    background: ${(props) => props.theme.colors.primary};
  }
`;

export const GridContainer = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 0.8fr 4.2fr;

  /* TABLET */
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
  }

  > aside {
    position: relative;

    /* TABLET */
    @media (max-width: 1280px) {
      display: none;
    }

    > ul {
      position: sticky;
      top: 1rem;
      border-radius: 10px;
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      background: ${(props) => props.theme.colors.bgBoxMenu};
      box-shadow: ${(props) => props.theme.colors.shadowBox};

      > li {
        margin: 0.25rem 0;
        display: flex;

        a {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          width: 100%;
          text-decoration: none;
          color: ${(props) => props.theme.colors.textColor};
          transition: all 0.25s ease-in-out;
          padding: 1rem;
          border-radius: 8px;
          font-weight: 600;
          font-size: 16px;

          &:hover {
            color: #ffffff;
            background: ${(props) => props.theme.colors.primary};
          }

          > svg {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  > main {
    position: relative;
    display: flex;
    flex-direction: column;

    > section:nth-child(1) {
      border-radius: 10px;
      padding: 2rem;
      background: ${(props) => props.theme.colors.bgBoxMenu};
      box-shadow: ${(props) => props.theme.colors.shadowBox};

      .text-left {
        text-align: left !important;
        justify-content: start !important;
      }
      .text-center {
        text-align: center !important;
      }
      .isName {
        width: 27%;
      }

      .thead-extra {
        border-radius: 5px 5px 0 0;
        width: 100%;
      }

      .first-c {
        border-radius: 5px 0 0 0;
      }

      .last-c {
        border-radius: 0 5px 0 0;
      }

      > div {
        > main {
          position: relative;
          border-radius: 5px;
          box-shadow: ${(props) => props.theme.colors.shadowMenu};
          border: 1px solid ${(props) => props.theme.colors.separator};

          > section {
            position: sticky;
            top: 1rem;
            border-radius: 5px 5px 0 0;
            width: 100%;
            display: grid;
            grid-template-columns: 1.5fr 2.3fr 0.8fr 0.8fr 0.8fr 0.7fr 2fr;
            z-index: 9;
            opacity: 1;
            box-shadow: 0px 12px 35px -13px ${(props) => props.theme.colors.primary},
              0px 25px 47px 2px rgba(0, 0, 0, 0);

            &:before {
              content: "";
              position: absolute;
              width: 101%;
              height: 20px;
              top: -18px;
              background: ${(props) => props.theme.colors.bgBoxMenu};
              border: 2px solid ${(props) => props.theme.colors.bgBoxMenu};
              left: -6px;
              //background: red;
            }

            > tr,
            th,
            td {
              background: #6c23c0;
              color: #ffffff;
              font-weight: bold;
              padding: 12px 15px;
              border-collapse: collapse;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 999999;
            }
          }

          > table {
            position: relative;
            width: 100%;
            border-collapse: collapse;
            font-size: 0.9em;
            overflow: hidden;
            border-bottom: 1px solid ${(props) => props.theme.colors.separator};

            td {
              padding: 12px 15px;
              color: ${(props) => props.theme.colors.textColor};

              > input {
                background: ${(props) => props.theme.colors.bgBoxMenu};
                border: 1px solid ${(props) => props.theme.colors.separator};
                box-shadow: ${(props) => props.theme.colors.shadowMenu};
                color: ${(props) => props.theme.colors.textColor};
                padding: 5px;
                border-radius: 4px;
                width: 80px;
                text-align: center;

                &::placeholder {
                  color: ${(props) => props.theme.colors.textColor}80;
                  text-align: center;
                }
              }

              &.anottation {
                > div {
                  text-align: center !important;

                  > img {
                    width: 26px;
                    filter: grayscale(1);

                    &.contain-note {
                      filter: unset !important;
                    }
                  }
                }
              }

              > select {
                /* padding: 8px 10px;
                border-radius: 4px;
                background: ${(props) => props.theme.colors.bgBoxMenu};
                border: 2px solid #b7b6c2;
                color: ${(props) => props.theme.colors.textColor}; */
              }
            }

            > thead {
              border-collapse: collapse;
              border-radius: 5px 5px 0 0;

              > tr,
              th,
              td {
                background: #6c23c0;
                color: #ffffff;
                font-weight: bold;
                padding: 12px 15px;
                border-collapse: collapse;
              }
            }

            > tbody {
              border-collapse: collapse;

              > tr {
                border-collapse: collapse;
                margin: 5px;

                &:last-child {
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const MobileTable = styled.div`
  > header {
    padding: 12px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #6c23c0;
    border-collapse: collapse;

    > aside {
      position: relative;
      display: flex;
      align-items: center;
      gap: 1rem;

      > h4 {
        color: #fff;
      }

      > div {
        position: relative;
        text-align: center !important;

        > img {
          width: 26px;
          filter: grayscale(1);

          &.contain-note {
            filter: unset !important;
          }
        }
      }
    }

    > div {
      padding-left: 1rem;

      > div {
        background: #54179c;
        border-radius: 50%;
        box-shadow: ${(props) => props.theme.colors.shadowMenu};

        h2 {
          color: #fff !important;
        }
      }
    }
  }

  > main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
    box-shadow: ${(props) => props.theme.colors.shadowMenu};

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > h5 {
        margin: 0 0 8px 0;
        color: ${(props) => props.theme.colors.textColor};
      }

      > input {
        background: ${(props) => props.theme.colors.bgBoxMenu};
        border: 1px solid ${(props) => props.theme.colors.separator};
        box-shadow: ${(props) => props.theme.colors.shadowMenu};
        color: ${(props) => props.theme.colors.textColor};
        padding: 5px;
        border-radius: 4px;
        width: 80px;
        text-align: center;
      }

      > select {
        padding: 5px;
        border-radius: 4px;
        background: ${(props) => props.theme.colors.bgBoxMenu};
        border: 2px solid #b7b6c2;
        color: ${(props) => props.theme.colors.textColor};
      }
    }
  }

  select {
    background: ${(props) => props.theme.colors.bgBoxMenu} !important;
  }
`;

export const NoneProgress = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const FormModalNotesContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  > header {
    position: relative;
    display: flex;
    margin-bottom: 1rem;

    > h1 {
      color: #3e3f5e;
    }
  }

  > main {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    > textarea {
      width: 100%;
      height: 100px;
      border: 1px solid #3e3f5e;
      color: #3e3f5e;
      border-radius: 6px;
      padding: 1rem;
      max-width: 100%;
      min-height: 200px;
      font-weight: 600;
    }

    > label {
      position: absolute;
      background: #fff;
      color: #3e3f5e;
      font-weight: 600;
      font-size: 0.8rem;
      transform: translate(10px, -10px);
      padding: 0 5px;
    }
  }
`;

export const FlashCardButtonOpen = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 6px;
  background: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  margin: 0 0 1rem 0;
  gap: 0.25rem;

  > span {
    text-transform: uppercase;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &:hover {
    background: ${(props) => props.theme.colors.secondary};
  }
`;
