import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;

  .goal-checked {
    background: ${props => props.theme.colors.primary};

    > h1 {
      background: ${props => props.theme.colors.alteranative2};
      color: ${props => props.theme.colors.black2};
    }

    > h2, span, p {
      color: #fff;
    }

    button {
      border: 1px solid #fff;

      &:hover {
        background: #73eaf640;
      }
    }

    svg {
      color: #77ffaa;
    }
  }

  .active {
    color: #ffffff;
    background: ${props => props.theme.colors.primary};
  }
`;

export const GridContainer = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 0.8fr 4.2fr;

  /* TABLET */
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
  }

  > aside {
    position: relative;

    /* TABLET */
    @media (max-width: 1280px) {
      display: none;
    }

    > ul {
      position: sticky;
      top: 1rem;
      border-radius: 10px;
      background: ${props => props.theme.colors.bgBoxMenu};
      box-shadow: ${props => props.theme.colors.shadowBox};
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 1rem;

      > li {
        margin: .25rem 0;
        display: flex;

        a {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          width: 100%;
          text-decoration: none;
          color: ${props => props.theme.colors.textColor};
          transition: all .25s ease-in-out;
          padding: 1rem;
          border-radius: 8px;
          font-weight: 600;
          font-size: 16px;

          &:hover {
            color: #ffffff;
            background: ${props => props.theme.colors.primary};
          }

          > svg {
            margin-right: .5rem;
          }
        }
      }
    }
  }

  > main {
    border-radius: 10px;
    background: ${props => props.theme.colors.bgBoxMenu};
    box-shadow: ${props => props.theme.colors.shadowBox};
    padding: 2rem;
    height: fit-content;

    > p {
      margin: 0 0 2rem 0;
      color: ${props => props.theme.colors.textColor};
    }

    > section {
      position: relative;
      display: flex;
      flex-direction: row;
      gap: 2rem;

      /* TABLET */
      @media (max-width: 1000px) {
        flex-direction: column;
        align-items: center;

        > div {
          margin-left: 1rem;
          width: 97% !important;
        }
      }

      > div {
        position: relative;
        //background: ${props => props.theme.colors.bg};
        box-shadow: ${props => props.theme.colors.shadowBox};
        border: 1px solid ${props => props.theme.colors.textColor}10;
        padding: 1rem 1rem 2rem 1rem;
        border-radius: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:first-child {
          margin-left: 1rem;
        }

        > h1 {
          position:relative;
          padding: 0 0.5em;
          font-size: 2rem;
          margin: 1rem 0 0 -1rem;
          color: #e6e2c8;
          border-radius: 0 0.156em 0.156em 0;
          background: ${props => props.theme.colors.alteranative};
          box-shadow: -1px 2px 3px rgba(0,0,0,0.5);
          font-weight: 500;
          line-height: 1.2;
          padding: 1rem;

          /* TABLET */
          @media (max-width: 1000px) {
            font-size: 1.5rem;
          }
        }

        > h1:before, > h1:after{
          position:absolute;
          content: '';
          display: block;
        }

        > h1:before{
          width: 0.469em;
          height: 100%;
          padding: 0 0 0.438em;
          top:0;
          left: -0.469em;
          background:inherit;
          border-radius: 0.313em 0 0 0.313em;

          /* TABLET */
          @media (max-width: 1000px) {
            left: -0.4em;
          }
        }

        > h1:after{
          width: 0.313em;
          height: 0.313em;
          background: rgba(0,0,0,0.35);
          bottom: -0.313em;
          left: -0.313em;
          border-radius: 0.313em 0 0 0.313em;
          box-shadow: inset -1px 2px 2px rgba(0,0,0,0.3);
        }

        > h2 {
          margin: 2rem 0 1rem 0;
          color: ${props => props.theme.colors.textColor};
          display: flex;
          align-items: center;

          /* TABLET */
          @media (max-width: 1000px) {
            font-size: 18px;
          }

          > svg {
            color: ${props => props.theme.colors.info};
            margin-left: .5rem;
          }
        }

        > p {
          color: ${props => props.theme.colors.textColor};
          display: flex;
          align-items: center;

          &:last-child {
            margin: 0 0 1rem 0;
          }

          > svg {
            margin: 0 .5rem 0 0;
          }
        }

        > hr {
          background-color: transparent;
          background-image: linear-gradient(90deg, rgba(206,211,246,0) 0, ${props => props.theme.colors.textColor}50 38%, ${props => props.theme.colors.textColor}50 64%, rgba(206,211,246,0) 99%);
          opacity: .3;
          margin: 30px 0 0;
          height: 1px;
        }

        > button {
          width: 100%;
          display: flex;
          justify-content: center;
          text-transform: uppercase;
          margin: 1rem 0 0 0;
          border-radius: 6px;
          padding: 16px 6px;
          color: #fff;
          font-weight: 600;
          background: ${props => props.theme.colors.primary};
          transition: all .2s ease-in-out;

          &:hover {
            background: ${props => props.theme.colors.primary}95;
          }
        }
      }
    }
  }
`;
