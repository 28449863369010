import React, { useCallback, useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useHistory } from 'react-router-dom';
import { DebounceInput } from 'react-debounce-input';
import Pagination from '../../../components/Pagination';
import Loader from '../../../components/Loader';
import { Container, ContentGrid3, HeaderTables, Main, MobileTable, Showing, Table } from './styles';

import pdfImg from '../../../assets/pdf.png';
import TooltipAdapter from '../../../components/TooltipAdapter';


interface IThemeProps {
  page: number;
  maxPages: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  records: {
    id: string;
    results: {
      id: string;
      user_id: string;
      theme_id: string;
      competence_i: number;
      competence_ii: number;
      competence_iii: number;
      competence_iv: number;
      competence_v: number;
      created_at: string;
      updated_at: string;
    }[];
    name: string;
    file?: string;
    file_url?: string;
    created_at: string;
    updated_at: string;
  }[];
}
interface IResultsProps {
  [key: string]: {
    theme_id: string;
    competence_i: number | null;
    competence_ii: number | null;
    competence_iii: number | null;
    competence_iv: number | null;
    competence_v: number | null;
  }
}

type Competences = 'competence_i' | 'competence_ii' | 'competence_iii' | 'competence_iv' | 'competence_v';

const CronogramaRedacaoComponent: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [mobileTable, setMobileTable] = useState(false);

  const [loading, setLoading] = useState(false);
  const [themes, setThemes] = useState<IThemeProps>({} as IThemeProps);

  const [results, setResults] = useState<IResultsProps>({} as IResultsProps);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get<IThemeProps>(`/themes`, {
          params: { pageIndex: currentPage - 1, pageSize: 15, }
        });

        console.log(response.data);
        setThemes(response.data);

        const resultsMaped: IResultsProps = {};

        response.data.records.forEach((theme) => {
          if (theme.results.length > 0) {
            resultsMaped[theme.id] = {
              competence_i: theme.results[0].competence_i,
              competence_ii: theme.results[0].competence_ii,
              competence_iii: theme.results[0].competence_iii,
              competence_iv: theme.results[0].competence_iv,
              competence_v: theme.results[0].competence_v,
              theme_id: theme.id,
            };
          }

          else {
            resultsMaped[theme.id] = {
              theme_id: theme.id,
              competence_i: null,
              competence_ii: null,
              competence_iii: null,
              competence_iv: null,
              competence_v: null,
            }
          }
        });

        setResults(resultsMaped);

        //pagination
        setShowingTo(response.data.showingTo);
        setShowingFrom(response.data.showingFrom);
        setMaxPages(response.data.maxPages);
        setTotalRecords(response.data.totalRecords);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [history, user, currentPage]);

  // Get window dimensions
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  }

  // verifica a dimensão do dispositivo
  useEffect(() => {
    if (windowDimensions.width <= 875) {
      setMobileTable(true);
    }
    else {
      setMobileTable(false);
    }
  }, [windowDimensions]);

  useEffect(() => {
    const handleResize = () => {
      const { width, height } = getWindowDimensions();
      setWindowDimensions({ width, height });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChangeValue = useCallback(async (value: number, competence: Competences, theme_id: string) => {
    let newValue = value;
    console.log('newValue', newValue);

    if (isNaN(value)) {
      newValue = 0;

      toast.error(`🤷‍♂️ Preencha o formulário apenas com números entre 0-200!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (value > 200) {
      newValue = 200;

      toast.error(`🤷‍♂️ As notas em cada competência devem estar entre 0 e 200 pontos!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (value < 0) {
      newValue = 0;

      toast.error(`🤷‍♂️ As notas em cada competência devem estar entre 0 e 200 pontos!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    const result = {
      ...results[theme_id],
      [competence]: newValue,
    }

    setResults((result) => ({
      ...result,
      [theme_id]: {
        ...result[theme_id],
        [competence]: newValue,
      }
    }));

    console.log('result antes:', result);

    if (result.competence_i === null || result.competence_ii === null || result.competence_iii === null || result.competence_iv === null || result.competence_v === null) {
      console.log('values: ', result.competence_i, result.competence_ii, result.competence_iii, result.competence_iv, result.competence_v)
      return;
    }

    try {
      console.log('result depois:', result);
      const response = await api.post(`/themes/${result.theme_id}/results`, {
        competence_i: result.competence_i,
        competence_ii: result.competence_ii,
        competence_iii: result.competence_i,
        competence_iv: result.competence_iv,
        competence_v: result.competence_v,
      });

      console.log(response.data);

      toast.success(`👌 Planilha de notas por competência atualizada com sucesso!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error);
    }

  }, [results]);

  useEffect(() => {
    const bd = document.querySelector('body');
    if (!mobileTable && bd) {
      bd.style.overflowX = 'hidden';
    }
  }, [mobileTable]);


  return (
    <Container>
      <section style={loading ? { height: '300px', overflow: 'hidden', position: 'relative' } : {}}>
        {loading && <Loader isFixed={false} borderRadius="0px" />}
        <Main>
          {!mobileTable && (
            <HeaderTables>
              <th className="text-left first-c">Tema</th>
              <th className="rest">PDF</th>
              <th className="rest">C1</th>
              <th className="rest">C2</th>
              <th className="rest">C3</th>
              <th className="rest">C4</th>
              <th className="rest">C5</th>
              <th className="rest last-c">Total</th>
            </HeaderTables>
          )}
          {themes.records?.map((theme, index) => (
            !mobileTable ? (
              <Table key={index}>
                <tbody>
                  <tr>
                    <td className="isName">
                      <h4>{theme.name}</h4>
                    </td>
                    <td className="text-center">
                      {theme.file_url ? (
                        <TooltipAdapter alt='Baixar PDF'>
                          <a href={theme.file_url} target="_blank" rel="noreferrer" key={theme.id}>
                            <img src={pdfImg} alt={theme.name} />
                          </a>
                        </TooltipAdapter>
                      ) :
                        (
                          <TooltipAdapter alt='Pdf não disponível' cursor='not-allowed'>
                            <img src={pdfImg} alt={theme.name} className='disabled' />
                          </TooltipAdapter>
                        )}
                    </td>
                    <td className="text-center">
                      <DebounceInput
                        type="text"
                        placeholder="0"
                        minLength={1}
                        maxLength={3}
                        min={0}
                        max={200}
                        debounceTimeout={500}
                        onChange={(e) => handleChangeValue(Number(e.target.value), 'competence_i', theme.id)}
                        value={(results[theme.id] && results[theme.id].competence_i !== null) ? String(results[theme.id].competence_i) : ''}
                      />
                    </td>
                    <td className="text-center">
                      <DebounceInput
                        type="text"
                        placeholder="0"
                        minLength={1}
                        maxLength={3}
                        min={0}
                        max={200}
                        debounceTimeout={500}
                        onChange={(e) => handleChangeValue(Number(e.target.value), 'competence_ii', theme.id)}
                        value={(results[theme.id] && results[theme.id].competence_ii !== null) ? String(results[theme.id].competence_ii) : ''}
                      />
                    </td>
                    <td className="text-center">
                      <DebounceInput
                        type="text"
                        placeholder="0"
                        minLength={1}
                        maxLength={3}
                        min={0}
                        max={200}
                        debounceTimeout={500}
                        onChange={(e) => handleChangeValue(Number(e.target.value), 'competence_iii', theme.id)}
                        value={(results[theme.id] && results[theme.id].competence_iii !== null) ? String(results[theme.id].competence_iii) : ''}
                      />
                    </td>
                    <td className="text-center">
                      <DebounceInput
                        type="text"
                        placeholder="0"
                        minLength={1}
                        maxLength={3}
                        min={0}
                        max={200}
                        debounceTimeout={500}
                        onChange={(e) => handleChangeValue(Number(e.target.value), 'competence_iv', theme.id)}
                        value={(results[theme.id] && results[theme.id].competence_iv !== null) ? String(results[theme.id].competence_iv) : ''}
                      />
                    </td>
                    <td className="text-center">
                      <DebounceInput
                        type="text"
                        placeholder="0"
                        minLength={1}
                        maxLength={3}
                        min={0}
                        max={200}
                        debounceTimeout={500}
                        onChange={(e) => handleChangeValue(Number(e.target.value), 'competence_v', theme.id)}
                        value={(results[theme.id] && results[theme.id].competence_v !== null) ? String(results[theme.id].competence_v) : ''}
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="text"
                        maxLength={3}
                        placeholder="0"
                        min={0}
                        max={200}
                        value={results[theme.id] && ((results[theme.id].competence_i || 0) + (results[theme.id].competence_ii || 0) + (results[theme.id].competence_iii || 0) + (results[theme.id].competence_iv || 0) + (results[theme.id].competence_v || 0))}
                        readOnly
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            )
              :
              (
                <MobileTable>
                  <header style={index === 0 ? { borderRadius: '5px 5px 0 0' } : {}}>
                    <aside>
                      <h4>{theme.name}</h4>
                      <button>
                        <img src={pdfImg} alt="" />
                      </button>
                    </aside>
                  </header>
                  <main style={index === (Number(themes.records.length) - 1 || 0) ? { borderRadius: '0 0 5px 5px' } : {}}>
                    <div>
                      <h5>C1</h5>
                      <DebounceInput
                        type="text"
                        placeholder="0"
                        minLength={1}
                        maxLength={3}
                        min={0}
                        max={200}
                        debounceTimeout={500}
                        onChange={(e) => handleChangeValue(Number(e.target.value), 'competence_i', theme.id)}
                        value={(results[theme.id] && results[theme.id].competence_i !== null) ? String(results[theme.id].competence_i) : ''}
                      />
                    </div>
                    <div>
                      <h5>C2</h5>
                      <DebounceInput
                        type="text"
                        placeholder="0"
                        minLength={1}
                        maxLength={3}
                        min={0}
                        max={200}
                        debounceTimeout={500}
                        onChange={(e) => handleChangeValue(Number(e.target.value), 'competence_ii', theme.id)}
                        value={(results[theme.id] && results[theme.id].competence_ii !== null) ? String(results[theme.id].competence_ii) : ''}
                      />
                    </div>
                    <div>
                      <h5>C3</h5>
                      <DebounceInput
                        type="text"
                        placeholder="0"
                        minLength={1}
                        maxLength={3}
                        min={0}
                        max={200}
                        debounceTimeout={500}
                        onChange={(e) => handleChangeValue(Number(e.target.value), 'competence_iii', theme.id)}
                        value={(results[theme.id] && results[theme.id].competence_iii !== null) ? String(results[theme.id].competence_iii) : ''}
                      />
                    </div>
                    <div>
                      <h5>C4</h5>
                      <DebounceInput
                        type="text"
                        placeholder="0"
                        minLength={1}
                        maxLength={3}
                        min={0}
                        max={200}
                        debounceTimeout={500}
                        onChange={(e) => handleChangeValue(Number(e.target.value), 'competence_iv', theme.id)}
                        value={(results[theme.id] && results[theme.id].competence_iv !== null) ? String(results[theme.id].competence_iv) : ''}
                      />
                    </div>
                    <div>
                      <h5>C5</h5>
                      <DebounceInput
                        type="text"
                        placeholder="0"
                        minLength={1}
                        maxLength={3}
                        min={0}
                        max={200}
                        debounceTimeout={500}
                        onChange={(e) => handleChangeValue(Number(e.target.value), 'competence_v', theme.id)}
                        value={(results[theme.id] && results[theme.id].competence_v !== null) ? String(results[theme.id].competence_v) : ''}
                      />
                    </div>
                    <div>
                      <h5>Total</h5>
                      <input
                        type="text"
                        maxLength={3}
                        placeholder="0"
                        min={0}
                        max={200}
                        value={results[theme.id] && ((results[theme.id].competence_i || 0) + (results[theme.id].competence_ii || 0) + (results[theme.id].competence_iii || 0) + (results[theme.id].competence_iv || 0) + (results[theme.id].competence_v || 0))}
                        readOnly
                      />
                    </div>
                  </main>
                </MobileTable>
              )
          ))}
        </Main>
      </section>
      <ContentGrid3>
        <Showing>{`Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} temas disponíveis`}</Showing>
        {totalRecords !== 0 && <Pagination count={maxPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
      </ContentGrid3>
    </Container>
  );
}

export default CronogramaRedacaoComponent;
