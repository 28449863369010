import styled from 'styled-components';

export const Container = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 1rem 0;

  > header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    > div:nth-child(2) {
      display: flex;
      flex-direction: row;
      gap: .5rem;
    }

    button {
      padding: 16px 20px;
      display: flex;
      font-size: 12px;
      box-shadow: none;

      > span {
        text-transform: uppercase;
      }

      /* MOBILE */
      @media (max-width: 480px) {

        > svg {
          margin: 0;
        }

        > span {
          display: none;
        }
      }
    }
  }

  .active {
    color: #ffffff;
    background: ${props => props.theme.colors.primary};
  }

  .is-date {

    &::before {
      content: '';
      position: absolute;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      z-index: 9999;
      top: -10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${props => props.theme.colors.info};
    }
  }

  .shake {
    width: 100%;
    animation: shake 1s;
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`;

export const BoardContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`;

export const ColumnName = styled.h2`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.textColor};
`;

export const DragabbleButtonContainer = styled.div`
  position: relative;
  margin: 0 0 8px 0;

  &:last-child {
    margin: 0;
  }
`;

export const DraggableButtonBlockMove = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const DraggableButton = styled.div`
  position: relative;
`;

export const DeleteButton = styled.div`
  position: absolute;
  height: 20px;
  width: 20px;
  background: #f14343;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -5px;
  right: -6px;
  cursor: pointer;
  z-index: 9999;
`;
